import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { SeoService } from '../../Services/seo.service'
@Component({
  selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})


export class CategoriesComponent {
    constructor(public seoservice: SeoService, ) {
    }
    ngOnInit(): void {
        this.seoservice.updateMetaTags2('Sign-up', '');
    }
}





