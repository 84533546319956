import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { AboutComponent } from './components/common/about/about.component'
import { ContactComponent } from './components/common/contact/contact.component'
import { CategoriesComponent } from './AzolePages/categories/categories.component';
import { LoginComponent } from './AzolePages/login/login.component';
import { ProductsComponent } from './AzolePages/products/products.component';
import { ProductsDetailsComponent } from './AzolePages/products-details/products-details.component';
import { RegistrationComponent } from './AzolePages/registration/registration.component';
import { RequestForQuoteComponent } from './AzolePages/request-for-quote/request-for-quote.component';
import { CartComponent } from './AzolePages/cart/cart.component';
import { ProcedToPayComponent } from './AzolePages/proced-to-pay/proced-to-pay.component'
import { MyOrdersComponent } from './AzolePages/my-orders/my-orders.component';
import { OrderInvoiceComponent } from './AzolePages/order-invoice/order-invoice.component';
import { LedgerreportsComponent } from './AzolePages/ledgerreports/ledgerreports.component';
import { AddressComponent } from './AzolePages/address/address.component';
import { AddaddressComponent } from './AzolePages/addaddress/addaddress.component'
import { UseraddressComponent } from './AzolePages/useraddress/useraddress.component'
import { FavouriteComponent } from './AzolePages/favourite/favourite.component'
import { ProfileComponent } from './AzolePages/profile/profile.component'
import { HowToOrderComponent } from './AzolePages/how-to-order/how-to-order.component';
import { DownloadsComponent } from './AzolePages/downloads/downloads.component';
import { SearchresultsComponent } from './AzolePages/searchresults/searchresults.component'
import { DistributorsRegComponent } from './AzolePages/distributors-reg/distributors-reg.component'
const routes: Routes = [

    { path: '', component: HomeOneComponent },
    { path: 'Cart', component: CartComponent },
    { path: 'MyOrders', component: MyOrdersComponent },
    { path: 'Invoice/:Invoice', component: OrderInvoiceComponent },
    { path: 'BuyNow', component: ProcedToPayComponent },
    { path: 'Login', component: LoginComponent },
    { path: 'Signup', component: CategoriesComponent },
    { path: 'Products/:CategoryName', component: ProductsComponent },
    { path: 'Product/:Productdetails/:CasNo/:Category', component: ProductsDetailsComponent },
    { path: 'Registration', component: RegistrationComponent },
    { path: 'RequestForQuote', component: RequestForQuoteComponent },
    { path: 'About', component: AboutComponent },
    { path: 'Contact', component: ContactComponent },
    { path: 'Companyprofle', component: HomeTwoComponent },
    { path: 'Leadership', component: HomeThreeComponent },
    { path: 'Careers', component: HomeFourComponent },
    { path: 'Certificates', component: HomeFiveComponent },
    { path: 'home-six', component: HomeSixComponent },
    { path: 'home-seven', component: HomeSevenComponent },
    { path: 'home-eight', component: HomeEightComponent },
    { path: 'home-nine', component: HomeNineComponent },
    { path: 'home-ten', component: HomeTenComponent },
    { path: 'home-eleven', component: HomeElevenComponent },
    { path: 'home-twelve', component: HomeTwelveComponent },
    { path: 'home-thirteen', component: HomeThirteenComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'Address', component: AddressComponent },
    { path: 'AddAddress', component: AddaddressComponent },
    { path: 'Useraddress', component: UseraddressComponent },
    { path: 'Favourite', component: FavouriteComponent },
    { path: 'Profile', component: ProfileComponent },
    { path: 'HowToOrder', component: HowToOrderComponent },
    { path: 'SearchCOA', component: DownloadsComponent },
    { path: 'Searchproduct/:searchtext', component: SearchresultsComponent },
    { path: 'DistributorReg', component: DistributorsRegComponent },

    // Here add new pages component
    { path: 'Supplier', component: LedgerreportsComponent },
    { path: '**', component: HomeOneComponent } // This line will remain down from the whole pages component list
];


@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
