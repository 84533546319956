import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'
import { SeoService } from '../../Services/seo.service'

@Component({
    selector: 'app-products',

    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})


export class ProductsComponent {
    loginDet: any;
    loginDetails: any;
    searchedProductsCount: number = 0;
    originalCategory: any[] = [];
    categoryName: string;
    arr: any = [];
    category: any = [];
    category1: any;
    isGridView: boolean;
    activeCategoryID: number;
    filteredProducts: any;
    HomeUrl: any;
    selectedProduct: any;
    image: any; activeCategoryname: any;
    token: any;
    filteredProductCount: number;
    Count: any;
    Counts: any;
    originalProducts: any[] = [];
    alphabetList: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    currentPage: number = 1;
    pageSize: number = 20;
    totalProducts: number = 0;
    paginatedProducts: any[] = [];
    items: any[] = []; // This would be your filteredProducts array
    totalPages: number = 0;
    loading: boolean = false;
    selectedAlphabet: string = 'A';
    selectedLetter: string = 'A'; 
    constructor(private route: ActivatedRoute, private viewportScroller: ViewportScroller, public router: Router, public generalService: GeneralService, public http: HttpClient, private seoService: SeoService,) {
        debugger
        this.GetCategory();
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
           
            this.loginDet = localStorage.getItem("LoginDetails");
        });


    }
    ngOnInit(): void {
        this.GetCategory();

        // Fetch route parameters (CategoryName) directly
        this.route.paramMap.subscribe(params => {
            this.categoryName = params.get('CategoryName'); // Get CategoryName from URL
            this.selectedAlphabet = 'A'; // Default alphabet set to 'A'

            // Call the function to get products based on CategoryName and default alphabet
           // this.getProductsByCategoryName(this.categoryName, this.selectedAlphabet);
            this.getProducts(this.categoryName, this.selectedAlphabet)
            // Optionally store the category name in localStorage
            localStorage.setItem("cat", this.categoryName);
        });
    }

    getCategoryName(categoryID: number): string {
        debugger
        const category = this.originalCategory.find(item => item.CategoryID === categoryID);
        
        const categoryName = category ? category.CategoryName : ''; // Get category name if found, otherwise empty string
        localStorage.setItem("cat", categoryName); // Store the categoryName in local storage
        return categoryName; // Return the category name
    }

    // Method to fetch categories
    GetCategory() {
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({});

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeUrl + "api/NovusChem/NC_Category_Crud";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {
                    this.originalCategory = data;
                    if (this.category && this.category.length > 0) {
                        this.activeCategoryID = this.category[0].CategoryID;
                        this.categoryName = this.category[0].CategoryName
                        this.selectedAlphabet = 'A'; // Set default alphabet here
                        this.getProducts(this.categoryName, this.selectedAlphabet); // Fetch products based on default alphabet
                    }
                    this.isGridView = true;
                },
                (err) => {
                });
            this.isGridView = true;
        });
    }

    private updateSeo(product: any) {
        debugger
        const productName = product;
        const Catalogue_Number = product[0].Catalogue_Number;
        this.seoService.updateMetaTags2(productName, Catalogue_Number);
    }

    // Method to fetch products
    getProducts(searchValue: any, selectedAlphabet: string) {
        debugger
        if (!searchValue && !selectedAlphabet) return; // Ensure we only proceed if there's a search value or an alphabet filter

        this.loading = true; // Show loader
        const formData = new FormData();
        formData.append('Param1', searchValue || ''); // Ensure Param1 is always set
        formData.append('Param2', selectedAlphabet || ''); // Ensure Param2 is always set

        const url = 'api/NovusChem/GetProductsByProductname_Letter';
        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            this.category = data;
            this.filteredProducts = this.category;
            this.updateSeo(this.categoryName);
            this.updatePagination();
            // Update pagination after fetching products
            this.loading = false; // Hide loader after fetching data
        }).catch(() => {
            this.loading = false; // Hide loader if error occurs
        });
    }

    // Method for filtering by alphabet
    filterByAlphabet(letter: string): void {
        this.selectedAlphabet = letter.toUpperCase(); // Update selected alphabet
        this.getProducts(this.categoryName, this.selectedAlphabet); // Call getProducts with updated alphabet
        this.selectedAlphabet = letter;
    }



    // Method to handle category click
    categoryClicked(categoryID: any): void {
        this.categoryName = categoryID;
        this.selectedAlphabet = 'A'; // Reset alphabet to default when changing category
        this.getProducts(this.categoryName, this.selectedAlphabet); // Fetch products with default alphabet
    }

    cleanCategoryName(categoryName: string): string {
        return categoryName.replace(/[(),%+\-\s]/g, '-'); // Clean category name for URL
    }

    // Pagination logic
    updatePagination(): void {
        this.totalPages = Math.ceil(this.filteredProducts.length / this.pageSize);
        this.updatePage();
    }

    updatePage(): void {
        const start = (this.currentPage - 1) * this.pageSize;
        const end = start + this.pageSize;
        this.paginatedProducts = this.filteredProducts.slice(start, end);
    }

    changePage(page: number): void {
        if (page >= 1 && page <= this.totalPages) {
            this.currentPage = page;
            this.updatePage();
        }
    }

    get pageNumbers(): number[] {
        return Array(this.totalPages).fill(0).map((_, index) => index + 1);
    }

    navigatingToSearchedProduct(pro: any) {
        debugger

        const cleanedProductName = pro.ProductName
            .replace(/[(),%+\-\s]/g, '')
        const cleanedcategory = this.categoryName
            .replace(/[(),%+\-\s]/g, '-')// Remove (, ), ,, %, +, -, and spaces

        const url = `${cleanedcategory}-${pro.CAS_No}-${cleanedProductName}`;
        this.router.navigate(['/Product/', cleanedcategory, pro.CAS_No,cleanedProductName, ]);
        localStorage.setItem('ProName', pro.ProductName)
        localStorage.setItem('category', cleanedcategory)
    }



    //navigatingToSearchedProduct(pro: any) {
    //    // Show the loader
    //    this.loading = true;

    //    // Clear the search results (category) immediately
    //    this.category = null;


     
    //    const cleanedProductName = pro
    //        .replace(/[(),%+\-\s]/g, '') // Remove (, ), ,, %, +, -, and spaces

    //    // Navigate to the product page
    //    this.router.navigate(['/Product/' + cleanedProductName]).then(() => {
    //        // Reload the page
    //        window.location.reload();
    //    }).catch(error => {
    //        console.error('Navigation to product page failed:', error);
    //    }).finally(() => {
    //        // Hide the loader
    //        this.loading = false;
    //    });
    //}

}
