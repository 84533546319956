import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SeoService } from '../../Services/seo.service'
@Component({
  selector: 'app-how-to-order',
  templateUrl: './how-to-order.component.html',
  styleUrls: ['./how-to-order.component.scss']
})
export class HowToOrderComponent {
    constructor(private router: Router, private seoService: SeoService,) {
    }
    ngOnInit() {

        this.seoService.updateMetaTags2('How-To-Order', '');
    }
    public goToLogin(): void {
        debugger
        localStorage.setItem('previousUrl', this.router.url); // Store the current URL
        this.router.navigate(['/Login']); // Navigate to the login page
    }
}
