<div id="about" class="about-area pt-100">
    <nav aria-label="breadcrumb" class="breadcrumb" style="background-color:#ffecec;">
        <div class="container">
            <ul style=" color: black;  ">
                <div style="color:#d03737;">COA Search</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:#d03737;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:#d03737;">COA SEARCH</li>
            </ul>
        </div>
    </nav>

     
     
       
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8">

                                <p>To proceed, please enter the Batch Number for the Certificate of Analysis (COA).</p>
                                <p><em>(COA Batch LOT No Examples are: NCHSA2024IP1, NCFIP2024FP2)</em></p>
                                <br />
                                <div class="input-group mb-3">
                                    <input type="text"
                                           class="form-control rounded-start "
                                           placeholder="Enter Batch No."
                                           aria-label="Enter Batch No."
                                           aria-describedby="button-addon2"
                                           [(ngModel)]="Batchno"
                                           style="border-right: none;" />
                                    <button class="btn rounded-end"
                                            type="button"
                                            id="button-addon2"
                                            (click)="batchno(Batchno)">
                                        Search
                                    </button>
                                </div>

                            </div>
                            <p>If you are unable to find the COA/MSDS, please send an email to <b>support-in@novuschems.com</b>.</p>
                            <br /><br />
                        </div>
                    </div>

    
</div>
