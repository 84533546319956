import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'
import { CartService } from '../../Services/cart.service'
import { SeoService } from '../../Services/seo.service'
@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent {
    HomeURL: any;
    loginDet: any;
    loginDetails: any;
    cartItems: any;
    COADATA: any;
    Batchno: any;
    arr: any[];

    constructor(public cartservice: CartService, private viewportScroller: ViewportScroller, private route: ActivatedRoute,
        public router: Router, public generalService: GeneralService, public http: HttpClient, private seoService: SeoService,) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
        });
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.cartservice.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res

        })
      
    }

    ngOnInit() {

        this.seoService.updateMetaTags2('SearchCoa', '');
    }

    batchno(batch: any) {
        debugger;
        const UploadFile = new FormData();
        UploadFile.append("Param", batch);
        const url = "api/NovusChem/Get_COAs_Basedon_BatchNo";

        this.generalService.PostData(url, UploadFile).then(data => {
            this.COADATA = data;
            if (this.COADATA && this.COADATA[0].COA_Pdf) {
                debugger
                // Assuming COA_PdfPath contains the URL to the PDF
                const pdfUrl = this.HomeURL + this.COADATA[0].COA_Pdf;

                // Open the PDF in a new window
                window.open(pdfUrl, '_blank');
            } else {
                console.error('PDF path not found in the response data.');
            }
        }, err => {
            console.error('pdf is not genarated:', err);
            // Optionally show an alert or error message
            // this.genralservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
        });
    }

}
