import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'
import { CartService } from '../../Services/cart.service'
import { SeoService } from '../../Services/seo.service'

@Component({
    selector: 'app-products-details',

    templateUrl: './products-details.component.html',
    styleUrls: ['./products-details.component.scss']
})
export class ProductsDetailsComponent {
    public form: FormGroup;
    //displayCOADropdown: boolean = false;

    arr: any = [];
    base64path: any;
    ProductName: any;
    Name: any;
    LoginDetails: any;
    Packsdeta: any;
    HomeURL: any;
    productDetails: any;
    ProductId: any;
    CategoryID: any;
    Accidental_Release_Mesaures: any;
    Stock_Variant: any;
    Books_ID: any;
    CAS_No: any;
    Catalog_No: any;
    Pick_List_3: any;
    Catalogue_Number: any;
    Density: any;
    Disposal_Considerations: any;
    Email: any;
    Exposure_Guidelines: any;
    First_Aid_Measures: any;
    Grade: any;
    Handling_And_Storage: any;
    logedinUser: any;
    quantity: any;
    HSN: any;
    loginDet: any;
    loginDetails: any;
    Record_Image: any; Owner: any; Molecular_Formula: any; Molecular_Weight: any;
    NLT_Purity: any; Other_Information: any; Personal_Protection: any; Price: any;

    quantities: any[] = []
    Cartimesforloginusers: any[];
    selectedItemforCart: any = []
    category1: any;
    activeCategoryID: any; activeCategoryID1: any;
    cartItems: any; subTotal: any = 0; totalPrice: any; Gstper: any; gtprice: any; Grandtotal = 0; Gamount: any; gstprice: any;
    items: any;
    Quantity: any;
    Quantity1: any;
    Quantityinc: any;
    flag: number;
    UOM: any;
    Comments: any;
    showCustomEnquiry: boolean = false;
    uomlist: any;
    deptList: any[] = [];
    UOMID: any;
    baseurl: string;
    bulkOrderData: any;
    pdf: any;
    relativeFilePath: any;
    HomeUrl: any;
    token: any;
    searchInputValue: string;
    COApdf: any[] = []; // Assuming COApdf contains all COA data
    COApdfFiltered: any[] = []; // Initialize COApdfFiltered as an empty array
    displayCOADropdown: boolean = false;
    favlist: any; FavorateItem: boolean = false;
    stockFetched: boolean = false;
    Surl: string;
    loading3: boolean = false;
    BatchNo: any;
    item: any;
    PackSize: string; // Define PackSize property
    PackName: string; // Define PackName property
    Enq_Id: any;
    Stockdata: any;
    sadata: any;
    ProName: any;
    lineitems: any[];
    Inchi: any;
    InchiKey: any;
    Tax: any;
    ISO_Smiles: any;
    Availabilty: any;
    ProductName1: any;
    originalCategory: any;
    filteredProductCount: any;
    discus: any;
    profiledata: any;
    packageprice: any;
    currency = 'IND';
    conversionRate = 0.01191;
    activeCurrency: string = 'IND';
    CategoryName: any;
    Category: any;
    constructor(public CartService: CartService
        , private viewportScroller: ViewportScroller, public activeroute: ActivatedRoute, public router: Router, public generalService: GeneralService, public http: HttpClient, public fb: FormBuilder, private seoService: SeoService) {
        for (let i = 1; i <= 10; i++) {
            this.quantities.push(i);
        }
        debugger
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            //this.HomeUrl = 'http://localhost:57391/'

        });
        this.ProductName = this.activeroute.snapshot.paramMap.get('Productdetails').replace(/\_/g, " ").trim();

        this.ProName = localStorage.getItem("ProName");
        this.Category = localStorage.getItem("category");
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.CartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res
            this.items = this.cartItems

        })
        this.cartItems.forEach((item: any) => {
            
            this.subTotal = (item.PackPrice * 1) * (item.Quantity); // Assuming Price is a number
            const gstTax = this.subTotal * (item.GSTTAX / 100);
            this.Grandtotal += this.subTotal 
            this.Gamount = this.Grandtotal + gstTax
            localStorage.setItem('Grantotal', this.Gamount);
        });

        this.generalService.getProducts().subscribe(res => {
            if (res.length == 0) {//if category id is null
                this.GetProductDetailsbasedonProductName()
            }
            else {
                //this.cartService.setRelatedproductId(res[0].Productid)
                this.ProductName = res[0].productname;
                this.GetProductDetailsbasedonProductName()

            }
        })
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
        });

        this.form = this.fb.group({
            UOM: ['', Validators.required] // Set up form control for Categoryname with required validation
        });

       // this.GetFav();
    }

    public goToLogin(): void {
        debugger
        localStorage.setItem('previousUrl', this.router.url); // Store the current URL
        this.router.navigate(['/Login']); // Navigate to the login page
    }

    ngOnInit(): void {
        this.GetUOM();
        //this.GetFav();
       
        this.activeroute.queryParams.subscribe(params => {

            const categoryID = params['categoryID'];
            if (categoryID) {
                this.GetProductsByCategory(categoryID);
            }
            const CategoryID = params['CategoryID'];
            if (CategoryID) {
                this.GetProductDetailsbasedonProductName()
            }
        });
       
   

        setInterval(() => {
            // this.onRefresh(event);
            this.ProductName = this.activeroute.snapshot.paramMap.get("Productdetails");
            //this.CategoryName = this.route.snapshot.paramMap.get("CategoryName");
        }, 1000);


        this.GetCOA();
        this.GetCategory();
        this.getprofile()
    }

    setCurrency(currency: string): void {
        this.currency = currency;
        this.activeCurrency = currency;
    }

    convertToUSD(price: number): number {
        return price * this.conversionRate;
    }

    cleanCategoryName(categoryName: string): string {
        return categoryName.replace(/[(),%+\-\s]/g, '-'); // Clean category name for URL
    }


    getprofile() {
        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
                RegId: this.loginDetails[0].RegId,
               
            });

            var UploadFile = new FormData();
            // const phone1 = this.LoginDetails ? this.LoginDetails[0].Phone1 : '';
            // const password = this.LoginDetails ? this.LoginDetails[0].PWD : '';

            // Encode the password to Base64
            //const encodedPassword = btoa(password);

            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", "6");

            var url = this.HomeUrl + "api/NovusChem/NC_Registation_GAUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => {

                this.profiledata = data;

                this.discus = this.profiledata.filter(a => a.RegId == this.loginDetails[0].RegId)
                // this.LoginDetails = this.profiledata;



            },
                err => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }
    GetCategory() {

        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({

            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeUrl + "api/NovusChem/NC_Category_Crud";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {
                    debugger

                    this.originalCategory = data;
                   
                    this.activeCategoryID = this.originalCategory[0].CategoryId;

                       
                    },
                   

               
                (err) => {
                });
           
        });
    }
 
    getFilteredProductCount(CategoryName: any) {
        debugger
        const cleanedProductName = CategoryName.replace(/[(),%+\-\s]/g, '-');
        this.router.navigate([`/Products/${cleanedProductName}`]);
        // const queryParams = this.route.snapshot.queryParams;
        //localStorage.setItem("cat", CategoryName);

    }

   
    onRefresh(event) {

        setTimeout(() => {
            this.GetProductDetailsbasedonProductName();
            if (event !== null) {
                event.target.complete();
            }
        }, 1000)
    }
    GetProductDetailsbasedonProductName() {
        debugger
        this.loading3 = true;
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.activeCategoryID1 = this.CategoryID;
            var UploadFile = new FormData()
            UploadFile.append("Param", this.ProName)

            var url = this.HomeUrl + "api/NovusChem/Get_Productdetails_basedon_ProductName";
            var accessToken = this.token;

          
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`
            });

         
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {
                    
                    this.productDetails = data;
                    this.updateSeo(this.productDetails)
                    this.ProductId = this.productDetails[0].ProductId
                    this.ProductName1 = this.productDetails[0].ProductName
                    this.CategoryID = this.productDetails[0].CategoryId                  
                    this.Catalog_No = this.productDetails[0].Catalog_No                 
                    this.CAS_No = this.productDetails[0].CAS_No                 
                    this.HSN = this.productDetails[0].HSN
                    this.Molecular_Formula = this.productDetails[0].Mol_Formula
                    this.Molecular_Weight = this.productDetails[0].Mol_Weight
                    this.Inchi = this.productDetails[0].Inchi
                    this.InchiKey = this.productDetails[0].InchiKey
                    this.Tax = this.productDetails[0].Tax
                    this.ISO_Smiles = this.productDetails[0].ISO_Smiles
                    this.Availabilty = this.productDetails[0].Availabilty
                    this.CategoryName = this.productDetails[0].CategoryName
                    
                    this.searchInputValue = '';
                   
                    this.Getpacks(this.Catalog_No)
                },
                (err) => {
                    //this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
            this.loading3 = false;
        });

    }
    Getpacks(catalog_no: any) {     
        var UploadFile = new FormData();
        UploadFile.append("pack", catalog_no);
        var url = "api/NovusChem/Get_Packs_Basedon_catelog";
        this.generalService.PostData(url, UploadFile).then(data => {

            this.category1 = data;

            this.category1.forEach(item => {
                item.Quantity = 1; // Initialize quantity property
            });
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });

    }
    discountstotal(item) {


        let totalWithGST = (item.PackPrice * item.Quantity);

        // Calculate the discount amount
        let discountAmount = totalWithGST * this.discus[0].Discount / 100

        // Apply the discount to the total
        let totalWithDiscount = totalWithGST - discountAmount;

        return totalWithDiscount;


    }
    updatePrice() {
        this.Price = this.gtprice * this.Quantity;
    }

    logedin() {
        this.logedinUser = localStorage.getItem('TokenID');
        return this.logedinUser;
    }

   
    setDiscount(item, discountValue): void {
        debugger
        item.Discount = discountValue ? parseFloat(discountValue) : null;
    }

    Addtocart(Products: any, item: any) {
        debugger;

        // Check if the user is logged in
        if (!this.loginDetails) {
            this.generalService.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'You are not logged in. Please login', 'Warning');
            this.router.navigate(['/Login']);
            return;
        }

        // Check if the item is already in the cart
        const existingItem = this.CartService.cartItemList.find(cartItem => cartItem.PackageId === item.PackageId);
        if (existingItem) {
            this.generalService.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'This item is already in the cart.', '');
            return;
        }

        // If the role is 3 and a special price is entered, use that special price instead of the pack price
        if (this.loginDetails[0]?.RoleId == 3 && item.Discount != null && item.Discount > 0) {
            this.packageprice = item.Discount - 0;  // Use the special price
        } else if (this.loginDetails[0]?.Discount == null) {
            this.packageprice = item.PackPrice;  // Use the default pack price
        } else {
            let totalWithGST = item.PackPrice;
            this.packageprice = totalWithGST - (item.PackPrice * this.discus[0].Discount / 100);  // Apply the discount
        }

        // Prepare the selected item to be added to the cart
        this.selectedItemforCart.push({
            ProductId: Products.ProductId,
            ProductName: Products.ProductName,
            CatalogNo: Products.Catalog_No,
            PackPrice: this.packageprice,  // Use the final calculated price (special price or pack price)
            PackName: item.PackName,
            HSN: Products.HSN,
            CAS: Products.CAS_No,
            GSTTAX: Products.Tax,
            PackageId: item.PackageId,
            Quantity: item.Quantity,
            DisDiscount: item.Discount
        });

        // Check if the user is logged in
        if (this.logedin()) {
            // Logic for logged-in users if needed
        } else {
            this.CartService.addtoCart(this.selectedItemforCart[0]);
        }

        // Show success alert
        this.generalService.ShowAlert('<img src="../../../assets/icons/icons8-success.gif" />', 'Added to Cart Successfully.', '');

        // Clear the selected items array after adding to cart
        this.selectedItemforCart = [];
    }

    insertCartItems(cartItems) {

        this.Cartimesforloginusers = []
        this.Cartimesforloginusers.push(cartItems)
        cartItems[0].UserId = String(this.loginDetails.ID)
        var url = "api/Azole/Insert_Customer_Orders";
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(cartItems));
        this.generalService.PostData(url, UploadFile).then((data: any) => {

            if (data != null) {

                var object = data.filter(x => x.Productid == cartItems[0].Productid)[0];
                this.CartService.addtoCart(object);
            }
        })
    }


    loading: boolean = false;
    loading1: boolean = false;
    loading2: boolean = false;
  


    GetProductsByCategory(categoryID: number) {

        this.activeCategoryID = categoryID;
        var UploadFile = new FormData();
        UploadFile.append("Param1", categoryID.toString());
        var url = "api/Azole/Get_Productsbycat";
        this.generalService.PostData(url, UploadFile).then(data => {

            this.category1 = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });

    }

   

  

    GetProductsByCategory1(CategoryName: any) {
        debugger

        const cleanedProductName = CategoryName.replace(/[(),%+\-\s]/g, '-');
        this.router.navigate([`/Products/${cleanedProductName}`]);
    }

    InsertBulkorders(Quantity1: string, UOM: string, Comments: string) {
        
        if (!Quantity1 || !UOM) {
            this.generalService.ShowAlert('Warning', 'Quantity and UOM are required.', 'Warning');
            return;
        }

        this.arr = [{
            Enq_Id: this.Enq_Id,
            PersonId: this.loginDetails[0].RegId,
            Quantity: Quantity1,
            UOMId: this.UOM.UOMId,
            Comments: Comments,
            ProductName: this.productDetails[0].ProductName,
            Catalog_No: this.productDetails[0].Catalog_No,
        }];

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');

        var url = 'api/NovusChem/Enquiries_CRUD';

        this.generalService.PostData(url, uploadfile).then((data:any) => {
                const response = JSON.parse(data);
            if (response.Status === "SUCCESS") {
                const enqId = response.Enq_Id;

                if (this.flag == 1) {
                   
                } else {
                    this.generalService.Alertsuccess('<img src="../../../assets/icons/icons8-success.gif" />', 'Enquiry placed, email successfully dispatched. ✔️', '');

                    this.send_Mail(this.arr[0], enqId);
                    this.router.navigate(['/MyOrders'], { queryParams: { selectedTab: 'enquiries' } });
                }
            } else {
                this.generalService.ShowAlert("Error", 'Failed to add the order. Please try again later.', 'error');
            }
        }).catch(error => {
            console.error("Error:", error);
         
            this.generalService.ShowAlert("Error", 'An unexpected error occurred. Please try again later.', 'error');
        });
    }

    toggleCustomEnquiry() {
         this.showCustomEnquiry = !this.showCustomEnquiry;
    }

    send_Mail(bulkOrderData: any, enqId: string) {
        const arr = [{
            ENQ_ID: enqId,
            EMailID: this.loginDetails[0].EMailID,
            UserName: this.loginDetails[0].UserName,
            Phone1: this.loginDetails[0].Phone1,
            Quantity: bulkOrderData.Quantity,
            PackSize: this.UOM.UOM,
            Comments: bulkOrderData.Comments,
            ItemName: bulkOrderData.ProductName,
            CasNumber: this.productDetails[0].CAS_No,

        }];

        const uploadFile = new FormData();
        uploadFile.append("Param", JSON.stringify(arr));
        const url = "api/NovusChem/Customer_Enquiry";

        this.generalService.PostData(url, uploadFile).then((data: any) => {
       
        });
    }

    GetUOM() {

        this.arr = [];
        this.arr.push({});

        var url = "api/NovusChem/GET_UOM";
        this.generalService.GetData(url).then(data => {

            this.uomlist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }



    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.uomlist.length; i++) {
                let type = this.uomlist[i];
                if (type.UOM.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                 //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    getCategoryNameFromLocalStorage(): string | null {
      
        return window.localStorage.getItem('cat');
    }

    decreaseQuantity(item) {
        if (item.Quantity > 1) {
            item.Quantity--;
        }
    }

    increaseQuantity(item) {
      
            item.Quantity++;
        
    }
    

    GetCOA() {

        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
                this.sendCOARequest();
            } else {
                this.generalService.ShowAlert('ERROR', 'Token not received', 'error');
            }
        }).catch(error => {
            console.error('Error fetching token:', error);
            this.generalService.ShowAlert('ERROR', 'Failed to fetch token', 'error');
        });
    }

    private sendCOARequest() {

        this.arr = [];
        this.arr.push({});

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');

        var url = this.HomeUrl + "api/Azole/COA_Curd";
        var accessToken = this.token; 
       
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${accessToken}`
        });

     
        this.http.post(url, UploadFile, { headers }).subscribe(

            (data: any) => {

                this.COApdf = data; 

                this.COApdfFiltered = this.COApdf.filter(coa => coa.Productid === this.ProductId);
                if (this.COApdfFiltered.length > 0) {

                    this.displayCOADropdown = true;
                } else {
                    
                    this.displayCOADropdown = false;
                }
            },
            (err) => {
                console.error('COA request failed:', err);
               // this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }

    async GetSafetydata(catalogueNumber: string) {
        try {
            
            this.loading1 = true;
            // Check if login details are present
            if (!this.loginDetails) {
                console.log('Login details not found');
                this.generalService.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'You are not logged in. Please login', 'Warning');
                this.router.navigate(['/Login']);
                return;  // Exit the function if not logged in
            }

            console.log('Login details found:', this.loginDetails);

            // Check if ZOHO_Books_ID is present
            if (!this.productDetails[0].CRMID) {
                console.log('ZOHO_Books_ID is null or undefined');
                this.generalService.ShowAlert('INFO', 'MSDS will be coming soon', 'info');
                return;  // Exit the function if ZOHO_Books_ID is not available
            }

            console.log('ZOHO_Books_ID found:', this.productDetails[0].CRMID);

            // Proceed with fetching the data
            const uploadFile = new FormData();
            const url = `api/Azole/Download_MSDS_Doc?recordId=${this.productDetails[0].CRMID}`;
            const data = await this.generalService.PostData(url, uploadFile);

            console.log('Data fetched:', data);

            this.base64path = data;
            this.loading1 = false;
            const binaryBuffer = this.base64ToBuffer(this.base64path);
            
            const blob = new Blob([binaryBuffer], { type: 'application/pdf' });
            
            this.Surl = URL.createObjectURL(blob);
            window.open(this.Surl, '_blank');
            
        } catch (error) {
            console.error('Error occurred:', error);
           // this.loading1 = false;
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        }
    }



    async GetSampleCOA(catalogueNumber: string) {
        
        try {
            this.loading2 = true;
            var Bookid = "450863000009299051";
            const uploadFile = new FormData();
            const url = `api/Azole/Download_Sampe_COA_Doc?Bookid=${Bookid}`;
            const data = await this.generalService.PostData(url, uploadFile);
            
            this.base64path = data;
            this.loading2 = false;
            const binaryBuffer = this.base64ToBuffer(this.base64path);
            this.showLoader();
            const blob = new Blob([binaryBuffer], { type: 'application/pdf' });

         
            this.Surl = URL.createObjectURL(blob);

            window.open(this.Surl, '_blank');
            this.hideLoader();
        } catch (error) {
            this.hideLoader();
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        }
    }

    base64ToBuffer(base64String: string): Uint8Array {
        const binaryString = atob(base64String);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    showLoader() {
        document.getElementById('loader').style.display = 'block';
    }

    // Function to hide loader
    hideLoader() {
        document.getElementById('loader').style.display = 'none';
    }
    async GetCOApdf(COA: string) {

        try {
            const uploadFile = new FormData();
            const url = `api/Azole/GetCOAPDF?COA=${COA}`;
            const data = await this.generalService.PostData(url, uploadFile);
            const relativeFilePath = data;
            const fullFilePath = this.HomeURL + '/' + relativeFilePath;
            window.open(fullFilePath, '_blank');
        } catch (error) {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        }
    }
    //order(selectedItems: { cat: any, hsn: any, cas: any }[]) {
    //    ;
    //    const queryParams = selectedItems.map(item => ({
    //        catno: item.cat,
    //        hsnno: item.hsn,
    //        casno: item.cas
    //    }));
    //    this.router.navigate(['/Favourite'], { queryParams });
    //}
    AddFav(value: any, item: any) {
        if (this.loginDetails[0] != 0) {
            if (!value.Catalogue_Number) {
                console.error("Catalogue_Number is undefined");
                return; // Exit the function early if Catalogue_Number is undefined
            }

            this.arr = [];
            this.arr.push({
                RegId: this.loginDetails[0]?.RegId, // Use optional chaining to avoid errors if loginDetails[0] is undefined
                PackageId: item.PackageId,
                Catalog_No: value.Catalogue_Number,
                Catalogue_Number: value.Catalogue_Number,
                HSN: value.HSN,
                CAS_No: value.CAS_No,
                ProductId: value.ProductId, // Access ProductId directly from Catalogue_Number,CAS_No
                // Name: value.Catalogue_Number.Name,
                CatalogNo: value.Catalogue_Number.Catalogue_Number,
                Record_Image: this.productDetails[0].Record_Image,
                Name: this.productDetails[0].Name,
                Price: this.Price,
                PackPrice: item.PackPrice,
                PackName: item.PackName,
                HSNCode: value.Catalogue_Number.HSN,
                CAS: value.Catalogue_Number.CAS_No,
                Quantity: item.Quantity,
          
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '1');

            var url = this.HomeUrl + "api/Azole/Favourite_CRUD";
            var accessToken = this.token;

            this.http.post(url, UploadFile).subscribe((data: any) => {
                if (data == "SUCCESS") {
                    this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-success.gif" />', 'Product added to favourites successfully!', '');
                    // this.router.navigate(['/Favourite']);
                } else {
                    this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Product already added to favourites', 'error');
                }
            });
        } else {
            // this.generalService.ShowAlert("Warning", 'Account Disabled, Please Contact to Admin.', 'Warning');
        }
    }

    // else {
    //       this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Product Already Added in Favourite', 'error');

    // }

    // }



    SearchandDownloadCOA(BatchNo: any) {
        
        var url = "api/Azole/SearchandDownloadCOA?BatchNo=" + BatchNo;
        this.generalService.GetData(url).then((data: any) => {
            
            if (data != "") {
                var COAResult = JSON.parse(data);
                if (COAResult.response.message == "Success") {
                    var COA = COAResult.response.downloadUrl
                    window.open(COA, '_blank');
                } else if (COAResult.response.message == "No records found for the given criteria.") {
                    this.generalService.ShowAlert('Alert', 'No records found for the given criteria.', 'error');
                }
            }
        })
    }


    GetFav() {
        
        if (this.loginDetails != undefined) {
            this.arr = [];
            this.arr.push({
                RegId: this.loginDetails[0].RegId
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = "api/Azole/Favourite_CRUD";

            this.generalService.PostData(url, UploadFile).then(data => {
                

                this.favlist = data;
                this.FavorateItem = this.favlist.some(item => item.Catalogue_Number === this.Catalogue_Number);
            },
                err => {
                    //this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        }
        else {
           // this.generalService.ShowAlert("Warning", 'Account Disabled, Please Contact to Admin.', 'Warning');

        }
        }
        // Call Gettoken and await its completion

    GetCoa() {

        this.router.navigate(['/SearchCOA']);
    }

    private updateSeo(product: any) {
        debugger
        const productName = product[0].ProductName;
        const Catalogue_Number = product[0].Catalog_No;
        const CategoryName = product[0].CategoryName;
        const CAS_No = product[0].CAS_No;
        this.seoService.updateMetaTags(productName, Catalogue_Number, CategoryName, CAS_No);
    }
}


